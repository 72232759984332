import Dropzone, { DropzoneOptions } from 'dropzone';
import * as ko from 'knockout';
import 'knockout.validation';
import {
    ConsultationTypeEnum,
    OpenVideoConsultationStatusEnum,
    ServiceTypeEnum,
} from '../../Common/enums';
import { fetch } from '@/Common/fetch';
import {
    IApproximateWaitingTimeResponse,
    IGetScheduleRequest,
    IOpenVideoConsultationRequest,
    IOpenVideoConsultationResult,
    ITimeSlotDto,
} from '../../Common/interfaces';
import Modal from '../../Common/Modal';
import { Texts } from '../../Common/Texts';
import { Utils } from '../../Common/Utils';
import { AlertViewModel } from '../AlertViewModel';
import { StepProgress } from '../StepProgress';
import { ReceiveVideoCall } from './ReceiveVideoCallFromDoctor';
import { addDays, format, isToday, parseISO, startOfToday } from 'date-fns';

enum Page {
    DetailsForm = 0,
    NotifyOwnDoctor = 1,
    Timetable = 2,
    Categories = 3,
    SelectCaregiver = 4,
}
interface ITimeslotsByDay {
    day: string;
    timeslots: Date[];
}

// Shim in case the browser does not support []#findLast (latest major browser to receive it was Firefox on 2022-08-22)
function findLast<T>(
    arr: Array<T>,
    fn: (element: T, index: number, array: Array<T>) => boolean,
): T | undefined {
    if ((arr as any).findLast) {
        return (arr as any).findLast(fn);
    }

    return arr.slice().reverse().find(fn);
}

const emptyUuid = '00000000-0000-0000-0000-000000000000';

export class BookVideoConsultationViewModel {
    public alertModal: AlertViewModel;
    public receiveVideoCall: ReceiveVideoCall;

    private selectCaregiverModal: string;
    private caregivers = ko.observableArray<{
        Uid: string;
        FullName: string;
    } | null>(null);
    public preferredCaregiver = ko.observable(emptyUuid);
    public preferredCaregiverName = ko.computed(() => {
        const caregivers = this.caregivers();
        if (caregivers == null) {
            return '';
        }

        const preferredCaregiver = this.preferredCaregiver();
        const matchingCaregiver =
            preferredCaregiver === emptyUuid
                ? null
                : caregivers.find((x) => x.Uid == preferredCaregiver);

        return (
            matchingCaregiver?.FullName ??
            Texts.get('BookVideoConsultation_FirstAvailableCaregiver')
        );
    });
    public cpr: string;
    public phone: KnockoutObservable<string>;
    public name: string;
    public subject: KnockoutObservable<string>;
    public attachments: KnockoutObservableArray<string>;
    public category = ko.observable<string | null>(null);
    private pages: Array<{
        page: Page;
        isEnabled(): boolean;
        isValid(): boolean;
    }>;
    private currentPage = ko.observable(Page.DetailsForm);
    public shouldShowDetailsForm = ko.computed(
        () => this.currentPage() == Page.DetailsForm,
    );
    public shouldShowNotifyOwnDoctor = ko.computed(
        () => this.currentPage() == Page.NotifyOwnDoctor,
    );
    public shouldShowTimetable = ko.computed(
        () => this.currentPage() == Page.Timetable,
    );
    public shouldShowCategories = ko.computed(
        () => this.currentPage() == Page.Categories,
    );
    public shouldShowSelectCaregiver = ko.computed(
        () => this.currentPage() === Page.SelectCaregiver,
    );
    public error: KnockoutValidationGroup;
    public checkboxValidation: KnockoutComputed<boolean>;

    public timeZoneWarning = Texts.get('TimeZoneWarning', {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    public showTimeZoneWarning =
        Intl.DateTimeFormat().resolvedOptions().timeZone != 'Europe/Copenhagen';

    public selectedTimeslot = ko.observable<Date>(null);

    // rateLimit prevents updating the timeslotsByDay computed on every insert
    private allTimeSlots = ko.observableArray<ITimeSlotDto>();
    public timeslots = ko.computed<Date[]>(() => {
        const preferredCaregiver = this.preferredCaregiver();
        let timeslots = this.allTimeSlots();
        if (preferredCaregiver !== emptyUuid) {
            timeslots = timeslots.filter((x) => {
                return x.AvailableCaregivers.includes(preferredCaregiver);
            });
        }
        const arrayOfTimeSlots = timeslots.map((x) => parseISO(x.Start));

        if (
            arrayOfTimeSlots.length > 0 &&
            this.consultationDraftScheduledOn != null
        ) {
            const consultationDraftScheduledOnFormatted = parseISO(
                this.consultationDraftScheduledOn,
            );
            const timeSlotDraft = arrayOfTimeSlots.find(
                (t) =>
                    t.toJSON() ==
                    consultationDraftScheduledOnFormatted.toJSON(),
            );

            if (timeSlotDraft) {
                this.selectedTimeslot(timeSlotDraft);
            }
        }

        return arrayOfTimeSlots;
    });

    public timeslotsByDay = ko.computed<ITimeslotsByDay[]>(() => {
        const timeslotMap: { [day: string]: Date[] } = {};

        // group the timeslots by date in a map
        this.timeslots().reduce((result, currentItem) => {
            const key = isToday(currentItem)
                ? Texts.getResource('Today')
                : format(currentItem, 'eeee') +
                  ', ' +
                  Utils.formatLongDate(currentItem);
            (result[key] = result[key] || []).push(currentItem);
            return result;
        }, timeslotMap);

        // convert the map to an array to simplify knockout rendering
        const timeslotArray: ITimeslotsByDay[] = [];

        for (const key in timeslotMap) {
            const value = timeslotMap[key];
            timeslotArray.push({ day: key, timeslots: value });
        }

        return timeslotArray;
    });

    public currentTimeslotDay = ko.observable<number>(0);

    public visibleTimeslotsPerDay = ko.computed<ITimeslotsByDay>(() => {
        return this.timeslotsByDay()[this.currentTimeslotDay()];
    });

    public isPreviousDayButtonVisible = ko.computed<boolean>(() => {
        return this.currentTimeslotDay() > 0;
    });

    public isNextDayButtonVisible = ko.computed<boolean>(() => {
        return !!this.timeslotsByDay()[this.currentTimeslotDay() + 1];
    });

    public isLongWaitingTime: KnockoutObservable<boolean>;

    private isOpenNow = ko.observable(false);
    private canQueueNow = ko.observable(false);
    public isVideoDevice = Utils.isVideoDevice();
    public isAzureSupportingDevice = ko.observable<boolean>(true);

    public isQueueNowButtonEnabled = ko.computed<boolean>(() => {
        if (!this.isVideoDevice) {
            return false;
        }

        if (this.preferredCaregiver() !== emptyUuid) {
            return false;
        }
        //we enable button in opening hours and also if opened for queueing (potentially before workhours)
        return this.isOpenNow() || this.canQueueNow();
    });

    public isQueueNowVisible = ko.computed<boolean>(() => {
        if (this.preferredCaregiver() !== emptyUuid) {
            return false;
        }
        return this.canQueueNow();
    });

    public scheduleTimeMessage = ko.computed<string>(() => {
        return this.isQueueNowVisible()
            ? Texts.get('BookTimeVideoCall')
            : Texts.get('NoDoctorsAvailableForQueueScheduleMessage');
    });

    public minutesWaiting: KnockoutObservable<string>;
    public notifyOwnDoctor: KnockoutObservable<boolean>;

    public hasLoadedTimeslots = ko.observable(false);
    public firstAvailableTimeslot = ko.observable<Date>(null);
    public firstAvailableTimeslotFormatted = ko.observable<string>(null);

    public stepProgress: StepProgress;
    private onBehalfUid: string | null = null;
    private onBehalfPetID: string = null;
    private isScheduleNow: boolean;
    private consultationType: ConsultationTypeEnum;
    private bookImmediately: boolean;
    private isBooking: boolean;
    private consultationHasCategories: boolean;
    private testResultUid: string = null;
    private questionnaireResult: any = null;
    private consultationUid: string | null = null;
    private consultationDraftScheduledOn: string | null = null;
    private dropzone: Dropzone;
    private dropzoneSelector = '#dropzone';
    private dropzoneCleaner: () => void;

    constructor(
        consultationType: ConsultationTypeEnum,
        serviceType: ServiceTypeEnum,
        price: number,
        consultationHasCategories: boolean,
        canQueueNow: boolean,
        stepProgress: StepProgress,
        skipConsultationDetails: boolean,
        bookImmediately: boolean,
        testResultUidParam: string,
        questionnaireResult: any,
        {
            caregivers,
            selectCaregiverModal,
        }: {
            caregivers: Array<{ Uid: string; FullName: string }> | null;
            selectCaregiverModal: string;
        },
        subjectTextDraft: string | null,
        notifyOwnDoctorEnabled: boolean,
        notifyOwnDoctorDraft: boolean,
        onBehalfOfNameDraft: string | null,
        onBehalfOfCprDraft: string | null,
        onBehalfOfUidDraft: string | null,
        onBehalfOfPetID: string | null,
        consultationUid: string | null,
        consultationDraftScheduledOn: string | null,
        preferredCaregiverUid: string | null,
        attachments: string[] = [],
        selectedPatientName: string | null,
        selectedPatientCpr: string | null,
        selectedPatientUid: string | null,
        isChildSelected: boolean,
        phone: string | null,
        isOpenNow: boolean | null,
    ) {
        const self = this;
        this.consultationType = consultationType;
        this.consultationHasCategories = consultationHasCategories;
        this.caregivers(caregivers);
        this.selectCaregiverModal = selectCaregiverModal;
        this.consultationUid = consultationUid;
        this.consultationDraftScheduledOn = consultationDraftScheduledOn;
        this.isOpenNow(isOpenNow);

        if (preferredCaregiverUid !== null) {
            this.preferredCaregiver(preferredCaregiverUid);
        }

        this.preferredCaregiver.subscribe(() => {
            Modal.close(this.selectCaregiverModal);
        });
        this.pages = [
            {
                page: Page.DetailsForm,
                isEnabled() {
                    return true;
                },
                isValid() {
                    return true;
                },
            },
            {
                page: Page.NotifyOwnDoctor,
                isEnabled() {
                    return notifyOwnDoctorEnabled;
                },
                isValid() {
                    return true;
                },
            },
            {
                page: Page.Categories,
                isEnabled: () => this.consultationHasCategories,
                isValid() {
                    return true;
                },
            },
            {
                page: Page.SelectCaregiver,
                isEnabled: () => {
                    const caregivers = this.caregivers();
                    return (
                        caregivers?.length &&
                        caregivers.find((x) => x.Uid === emptyUuid) == null
                    );
                },
                isValid: () =>
                    this.caregivers()?.find((x) => x.Uid === emptyUuid) !=
                        null || this.preferredCaregiver() !== emptyUuid,
            },
            {
                page: Page.Timetable,
                isEnabled() {
                    return true;
                },
                isValid() {
                    return true;
                },
            },
        ];
        self.alertModal = new AlertViewModel();
        self.receiveVideoCall = new ReceiveVideoCall();
        self.bookImmediately = bookImmediately;
        self.canQueueNow(canQueueNow);
        self.testResultUid = testResultUidParam;
        self.questionnaireResult = questionnaireResult;

        Utils.isAzureSupportingDevice().then((isAzureSupportingDevice) => {
            self.isAzureSupportingDevice(isAzureSupportingDevice);
        });

        self.phone = ko.observable(null).extend({
            pattern: {
                params: '^[+]?[0-9]{8,20}$',
                message: Texts.getResource('InvalidPhoneNumber'),
            },
            validation: {
                validator: function (val: string) {
                    return val !== null && val.trim() !== '';
                },
                message: Texts.getResource('PhoneNumberRequired'),
            },
            phone: !skipConsultationDetails,
        });
        this.phone(phone);

        self.subject = ko.observable(null).extend({
            required: {
                params: !skipConsultationDetails,
                message: Texts.getResource('SubjectRequired'),
            },
        });

        self.attachments = ko.observableArray(attachments);

        self.error = ko.validatedObservable(this);
        self.isLongWaitingTime = ko.observable(false);
        self.minutesWaiting = ko.observable(null);
        self.notifyOwnDoctor = ko.observable(false);
        self.error.errors.showAllMessages(false);

        self.stepProgress = stepProgress;

        if (self.testResultUid !== null) {
            self.subject(Texts.get('DynamicCodeSubject'));
        } else if (
            self.consultationType == ConsultationTypeEnum.AllergyCheckVideo
        ) {
            self.subject('Allergitjek');
        }

        const loadDraft: boolean = subjectTextDraft !== null;
        const loadBehalfOfDraft: boolean =
            loadDraft && onBehalfOfNameDraft !== null;
        const isPatientSelected: boolean = selectedPatientName !== null;

        if (loadDraft) {
            self.subject(subjectTextDraft);
            self.notifyOwnDoctor(notifyOwnDoctorDraft);
        }

        self.onBehalfPetID = onBehalfOfPetID;

        if (isChildSelected) {
            self.name = selectedPatientName;
            self.cpr = selectedPatientCpr;
            self.onBehalfUid = selectedPatientUid;
        } else if (loadBehalfOfDraft && !isPatientSelected) {
            self.name = onBehalfOfNameDraft;
            self.cpr = onBehalfOfCprDraft;
            self.onBehalfUid = onBehalfOfUidDraft;
        } else {
            self.name = selectedPatientName;
            self.cpr = selectedPatientCpr;
        }

        if (self.bookImmediately) {
            self.executeSeeDoctorNow();
        }

        if (document.querySelector(this.dropzoneSelector)) {
            const dropzoneOptions: DropzoneOptions = {
                url: '/api/files/upload',
                method: 'POST',
                success: (file) => {
                    const uid = JSON.parse(file.xhr.responseText);
                    (file as any).uid = uid;
                    this.attachments.remove(null);
                    this.attachments.push(uid);
                },
                init: function () {
                    this.on('addedfile', function (file) {
                        if (file.size > dropzoneOptions.maxFilesize) {
                            alert(
                                `${Texts.getResource(
                                    'UploadFailedTooLarge',
                                ).replace('{0}', file.name)}`,
                            );
                            self.dropzone.removeFile(file);
                        }

                        const dropzoneContainer =
                            document.getElementById('dropzone');
                        const previewElements =
                            document.querySelectorAll('.dz-preview');
                        if (previewElements.length !== 0) {
                            dropzoneContainer.style.height = 'auto';
                        }
                    });
                    this.on('removedfile', function (file) {
                        const dropzoneContainer =
                            document.getElementById('dropzone');
                        const previewElements =
                            document.querySelectorAll('.dz-preview');
                        if (previewElements.length === 0) {
                            dropzoneContainer.style.height = '0';
                        }
                    });
                },
                addRemoveLinks: true,
                acceptedFiles: 'image/jpeg,image/png,image/gif,.pdf,.mov',
                maxFiles: 10,
                maxFilesize: 30_000_000,
            };

            self.dropzone = new Dropzone(
                self.dropzoneSelector,
                dropzoneOptions,
            );

            self.dropzoneCleaner = () => {
                self.dropzone.removeAllFiles(true);
                $(self.dropzoneSelector).empty();
            };

            self.dropzone.on('removedfile', (file: any) => {
                self.attachments.remove(file.uid);
            });

            if (consultationUid != null) {
                $.get(`/api/files/attachments/${consultationUid}`, (data) => {
                    $.each(data, (key, value) => {
                        const mockFile = {
                            name: value.FileName,
                            size: value.FileByteSize,
                        };
                        self.dropzone.emit('addedfile', mockFile);
                        self.dropzone.options.thumbnail.call(
                            self.dropzone,
                            mockFile,
                            '/api/files/' + value.UID,
                        );
                        self.dropzone.emit('complete', mockFile);
                    });
                });
            }
        }
    }

    public showSelectCaregiver() {
        Modal.show(this.selectCaregiverModal);
    }

    public openBookingBusyModal(): void {
        Modal.show('bookingbusy-modal');
    }
    public bookChooseTime(): void {
        //just close modal, we are already on book time page
        Modal.close('bookingbusy-modal');
    }
    public bookNextAvailableTimeslot(): void {
        Modal.close('bookingbusy-modal');
        this.selectedTimeslot(this.firstAvailableTimeslot());
        this.executeScheduleConsultationOnSpecificDate();
    }

    public uploadAttachment = () => {
        $(this.dropzoneSelector).trigger('click');
    };

    public clearQuestionnaireData(): void {
        document.cookie =
            'QuestionnaireData' +
            '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.cookie =
            'QuestionnaireServiceType' +
            '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        document.location.reload();
    }

    // for unknown reasons `this` is set to the date parameter on click
    // so we have to pass in a reference to the view model manually
    public selectTimeslot(
        self: BookVideoConsultationViewModel,
        date: Date,
    ): void {
        self.selectedTimeslot(date);
    }

    public acceptNotifyOwnDoctor(): void {
        this.notifyOwnDoctor(true);
        this.navigateForward();
    }

    public declineNotifyOwnDoctor(): void {
        this.notifyOwnDoctor(false);
        this.navigateForward();
    }

    public nextDayClicked() {
        this.currentTimeslotDay(this.currentTimeslotDay() + 1);
    }

    public previousDayClicked() {
        this.currentTimeslotDay(this.currentTimeslotDay() - 1);
    }

    public openNextAfterDetailsForm(): void {
        if (!this.error.isValid()) {
            this.error.errors.showAllMessages(true);
            return;
        }

        this.navigateForward();
    }

    public categorySelected(): void {
        if (!this.category()) {
            return;
        }
        this.navigateForward();
    }

    public navigateBack(): void {
        const currentIndex = this.pages.findIndex(
            (x) => x.page === this.currentPage(),
        );
        const next = findLast(
            this.pages,
            (x, idx) => idx < currentIndex && x.isEnabled(),
        );
        if (next == null) {
            return;
        }

        this.navigate(next.page);
    }

    public navigateForward() {
        const currentIndex = this.pages.findIndex(
            (x) => x.page === this.currentPage(),
        );

        const current = this.pages[currentIndex];
        if (!current.isValid()) {
            return;
        }

        const next = this.pages.find(
            (x, idx) => idx > currentIndex && x.isEnabled(),
        );
        if (next == null) {
            return;
        }

        this.navigate(next.page);
    }

    private navigate(page: Page): void {
        this.currentPage(page);
        switch (page) {
            case Page.SelectCaregiver:
                this.stepProgress.current(2);
                break;
            case Page.NotifyOwnDoctor:
                this.stepProgress.current(1);
                break;
            case Page.Categories:
                this.stepProgress.current(2);
                break;
            case Page.DetailsForm:
                this.stepProgress.current(1);
                break;
            case Page.Timetable:
                this.stepProgress.current(
                    this.consultationHasCategories ? 3 : 2,
                );
                this.openVideoConsultationDetails();
                break;
        }
        this.scrollStepperIntoView();
    }

    private scrollStepperIntoView() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    private openVideoConsultationDetails(): void {
        this.getSchedule();
        this.getApproximateWaitingTime();
    }

    public backToDetails() {
        this.navigate(Page.DetailsForm);
    }

    public executeSeeDoctorNow() {
        /* if caregiver is avalable now, start video consultation, else show booking busy dialog instead*/
        if (this.canQueueNow()) {
            this.seeDoctorNow();
        } else {
            this.openBookingBusyModal();
        }
    }

    public seeDoctorNow(): void {
        $('.js-schedule-now-btn').addClass('button--loading');

        this.isScheduleNow = true;
        this.selectedTimeslot(null);
        this.openVideoConsultation();
    }

    public executeScheduleConsultationOnSpecificDate() {
        this.scheduleConsultationOnSpecificDate();
    }

    public scheduleConsultationOnSpecificDate(): void {
        this.isScheduleNow = false;

        if (this.selectedTimeslot() == null) {
            this.alertModal.openAlert(Texts.getResource('PleaseChooseDate'));
            return;
        }

        $('.js-schedule-btn').addClass('button--loading');

        this.openVideoConsultation();
    }

    public openVideoConsultation(): void {
        const self = this;

        if (!self.error.isValid()) {
            self.error.errors.showAllMessages();
            $('.js-schedule-now-btn').removeClass('button--loading');
            $('.js-schedule-btn').removeClass('button--loading');
            return;
        }

        if (self.isBooking) {
            return;
        }

        self.isBooking = true;

        const callbackError = (
            response: any,
            status: any,
            error: any,
        ): void => {
            self.isBooking = false;
            switch (response.status) {
                case 402:
                    self.alertModal.openAlert(
                        Texts.getResource('InvalidPayment'),
                    );
                    break;
                case 409:
                    if (
                        response.responseJSON.Status ===
                        OpenVideoConsultationStatusEnum.TimeSlotOverbooked
                    ) {
                        self.alertModal.openAlert(
                            Texts.getResource('TimeSlotUnavailable'),
                        );
                        self.getSchedule();

                        break;
                    }
                default:
                    self.alertModal.openAlert(
                        Texts.getResource('ErrorSchedulingConsultation'),
                    );
                    break;
            }
            $('.js-schedule-now-btn').removeClass('button--loading');
            $('.js-schedule-btn').removeClass('button--loading');
        };

        const callbackSucces = (
            response: IOpenVideoConsultationResult,
            status: any,
            error: any,
        ): void => {
            if (response.Status === 0 && response.NewConsultationGuid) {
                let redirectUrl: string;
                if (self.isScheduleNow) {
                    redirectUrl =
                        window.location.origin +
                        '/consultations/' +
                        response.NewConsultationGuid +
                        '/videocall';
                } else {
                    const scheduledDateParam =
                        '?scheduled=' +
                        encodeURIComponent(
                            self.selectedTimeslot().toISOString(),
                        );

                    redirectUrl =
                        window.location.origin +
                        '/consultations/video/' +
                        response.NewConsultationGuid +
                        '/scheduled-date' +
                        scheduledDateParam;
                }

                if (response.RequiresPayment) {
                    window.location.href =
                        window.location.origin +
                        '/payment/plan?consultation=' +
                        response.NewConsultationGuid;
                } else {
                    window.location.href = redirectUrl;
                }
            } else {
                callbackError(response, status, error);
            }
        };

        self.createVideoConsultation(callbackSucces, callbackError);
    }

    public createVideoConsultation(
        successCallback: (
            response: any,
            status: string,
            jqXhr: JQueryXHR,
        ) => any,
        errorCallback: (jqXhr: JQueryXHR, status: string, error: string) => any,
    ): void {
        const request: IOpenVideoConsultationRequest = {
            FromFullName: this.name,
            Category: this.category(),
            CprNumber: this.cpr,
            MobileNumber: this.phone(),
            Subject: this.subject() || 'Videokonsultation',
            OnBehalfUID: this.onBehalfUid,
            OnBehalfOfPetID: this.onBehalfPetID,
            ConsultationType: this.consultationType,
            NotifyOwnDoctor: this.notifyOwnDoctor(),
            TestResultUid: this.testResultUid,
            QuestionnaireResult: this.questionnaireResult,
            PreferredCaregiverUid: this.preferredCaregiver() || null,
            ConsultationUid: this.consultationUid,
            FileUids: this.attachments(),
        };
        if (this.selectedTimeslot() != null) {
            request.ScheduledOn = this.selectedTimeslot();
        }

        Utils.ajaxCall(
            'POST',
            'api/consultations/video',
            successCallback,
            errorCallback,
            JSON.stringify(request),
        );
    }

    private async getSchedule(): Promise<void> {
        const startingDate = startOfToday();
        const endingDate = addDays(new Date(), 6);

        const request: IGetScheduleRequest = {
            FromDate: startingDate.toJSON(),
            ToDate: endingDate.toJSON(),
            ConsultationType: this.consultationType,
            ConsultationDraftScheduledOn: this.consultationDraftScheduledOn,
        };

        try {
            const query = Object.entries(request)
                .map(([key, value]) => key + '=' + encodeURIComponent(value))
                .join('&');
            const response: ITimeSlotDto[] = await fetch(
                '/api/consultations/video/schedule/v2?' + query,
            );

            this.allTimeSlots.removeAll();
            this.allTimeSlots.push(...response);
            this.hasLoadedTimeslots(true);
            if (this.allTimeSlots().length > 0) {
                const firstTimeslot = parseISO(this.allTimeSlots()[0].Start);

                this.firstAvailableTimeslot(firstTimeslot);
                this.firstAvailableTimeslotFormatted(
                    Utils.formatLongDateTime(this.allTimeSlots()[0].Start),
                );
            }
        } catch (error) {
            this.alertModal.openAlert(
                Texts.getResource('ErrorFetchingTimetable'),
            );
        }
    }

    private getApproximateWaitingTime(): void {
        const callBackAjax = (
            response: IApproximateWaitingTimeResponse,
            status: any,
            error: any,
        ): void => {
            if (!response.IsQueueEnabled || !Utils.isVideoDevice()) {
                this.disableQueueNow();
                return;
            }

            const waitingTimeMinutes = Utils.timeSpanStringToMinutes(
                response.ApproximateWaitingTime,
            );

            const waitingTimeString =
                waitingTimeMinutes === 1
                    ? Texts.get('WaitingTimeText_Singular', {
                          minutes: waitingTimeMinutes.toString(),
                      })
                    : Texts.get('WaitingTimeText_Other', {
                          minutes: waitingTimeMinutes.toString(),
                      });

            this.minutesWaiting(waitingTimeString);
            this.isLongWaitingTime(response.IsLongWaitingTime);
        };

        const errorCallBack = (response: JQueryXHR): void => {
            this.alertModal.openAlert(
                Texts.getResource('ErrorFetchingTimetable'),
            );
        };

        Utils.ajaxCall(
            'GET',
            `api/consultations/video/waitingtime/v2?consultationType=${this.consultationType}`,
            callBackAjax,
            errorCallBack,
        );
    }

    private disableQueueNow(): void {
        this.canQueueNow(false);
    }
}
